import PropTypes from "prop-types";
import React from "react";
import Button from "../components/button";

function Hero({ props }) {
    let style = {};

    return (
        <section
            className={`py-4 md:py-${
                props.padding ? props.padding : `16`
            } md:-mt-24 bg-secondary dark:bg-gray-800`}
            style={{ ...style, ...props.style }}
        >
            <div
                className={`flex flex-col md:flex-row w-full max-w-6xl mx-auto px-4 py-8 md:pt-16 md:pb-4 ${
                    props.align ? props.align : `text-center`
                }`}
            >
                <div
                    className={props.width ? props.width : `mx-auto max-w-3xl`}
                >
                    <h2 className="text-sm uppercase tracking-widest">
                        {props.label}
                    </h2>

                    <h1 className="text-3xl md:text-5xl mt-4 leading-tight font-display">
                        {props.title}
                    </h1>

                    {props.desc != "" ? (
                        <p className="text-lg md:text-2xl mt-10">
                            {props.desc}
                        </p>
                    ) : null}

                    {typeof props.cta !== "undefined" ? (
                        <div className="flex flex-wrap mt-12 justify-center">
                            {props.cta.map((item) => (
                                <Button
                                    key={item.text}
                                    text={item.text}
                                    size="lg"
                                    color={item.color}
                                    url={item.url}
                                    className="cta_hero"
                                />
                            ))}
                        </div>
                    ) : null}
                </div>
                {typeof props.contentImg !== "undefined" ? (
                    <div className="w-full md:w-1/2">{props.contentImg}</div>
                ) : null}
            </div>
        </section>
    );
}

Hero.propTypes = {
    props: PropTypes.object,
    padding: PropTypes.string,
    label: PropTypes.string,
    title: PropTypes.string,
    width: PropTypes.string,
    align: PropTypes.string,
    desc: PropTypes.string,
    style: PropTypes.array,
    cta: PropTypes.array,
    contentImg: PropTypes.string,
};

export default Hero;
