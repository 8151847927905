import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Cta from "../components/cta";
import Hero from "../components/hero";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import parse from "html-react-parser";

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from "react-accessible-accordion";

function Faq({ data }) {
    let d = data.contentfulFaq;

    return (
        <Layout>
            <SEO title={d.title} description={d.subtitle} />

            <Hero
                props={{
                    padding: `10`,
                    title: d.title,
                    desc: d.subtitle,
                }}
            />

            <section>
                <div className="w-full max-w-5xl mx-auto px-4 py-8 md:px-8 md:py-16 ">
                    <Accordion>
                        {d.content.map((item, i) => (
                            <AccordionItem
                                key={i}
                                className="p-4 border border-gray-100 dark:border-gray-800 shadow-lg rounded-lg mb-6 bg-white dark:bg-gray-700"
                            >
                                <AccordionItemHeading>
                                    <AccordionItemButton className="focus:outline-none">
                                        <h5 className="text-xl font-sans leading-tight">
                                            {parse(item.q)}
                                        </h5>
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p className="mt-3 text-gray-500 dark:text-gray-400">
                                        {parse(item.a)}
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>
                        ))}
                    </Accordion>
                </div>
            </section>

            <Cta />
        </Layout>
    );
}

Faq.propTypes = {
    data: PropTypes.object,
};

export const query = graphql`
    {
        contentfulFaq {
            title
            subtitle
            content {
                q
                a
            }
        }
    }
`;

export default Faq;
